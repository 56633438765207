$white: #fff;
$black: #000;

$transparent-100: rgba(51, 51, 51, 0.1);
$transparent-120: rgba(156, 156, 156, 0.25);
$transparent-140: rgba(0, 0, 0, 0.3);

$brand-orange: #ff7a00;
$brand-orange-100: #e35f4d;
$brand-orange-120: #f1963a;

$brand-gray: #333233;
$brand-gray-100: #f5f5f5;
$brand-gray-120: #ededed;
$brand-gray-140: #2b3037;
$brand-gray-160: #acb1b8;
$brand-gray-180: #333333;
$brand-gray-200: #576169;
$brand-gray-220: #e2e2e2;
$brand-gray-240: #f8f8f8;
$brand-gray-260: #e5e5e5;
$brand-gray-280: #373737;
$brand-gray-300: #eeeeee;
$brand-gray-320: #3a3a3c;
$brand-gray-340: #8f90a6;
$brand-gray-360: #8b8b8b;

$brand-blue: #1e5ab6;
$brand-blue-100: #5073b8;

$brand-green: #0ab39c;
