@import '../../styles/variables/variables';


.user-info {

  background-color: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 24px;


  &-title {
    margin-top: -30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-text {

      & h2 {
        height: 22px;
        font-family: Verdana, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #333333;
      }
    }



    &-back {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;

      & img {
        width: 11px;
        height: 19px;
        margin-right: 5px;
      }

      & span {
        width: 37px;
        height: 22px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.5px;
        color: #000000;
      }
    }

  }

  &-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: 50px;

      &-notImage {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 96px;
        width: 96px;
        background-color: $brand-orange-120;

        & span {
          text-transform: capitalize;
          color: $white;
          font-size: 50px !important;
        }
      }

      &-hasImage {
        border-radius: 50%;
      }

      &-project {
        margin-left: 30px;
        font-size: 14px;
        line-height: 17px;
        color: $brand-gray-320;
        position: relative;

        span {
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: $brand-gray-340;
        }

        .green-icon {
          position: absolute;
          left: -10px;
          top: 5px;
        }
      }

      &-points {
        margin-left: auto;
        margin-right: 100px;
        margin-top: 32px;
        margin-bottom: 32px;

        div {
          height: 50px;
          width: 250px;
          margin-bottom: 10px;
        }

        &__d1,
        &__d2,
        &__d3 {
          border-bottom: 5px solid $brand-blue-100;
          display: flex;
          justify-content: center;

          .left {
            display: flex;
            align-items: center;
            flex-direction: column;
            font-size: 14px;
            color: $brand-gray-320;
            font-weight: 500;
            width: 45%;

            span {
              font-weight: 600;
              line-height: 26px;
              letter-spacing: -0.3px;
              color: $brand-orange;
            }
          }

          .right {
            background-color: $brand-gray-240;
            border-bottom: 5px solid $brand-blue-100;
            display: flex;
            align-items: center;
            padding-left: 10px;
            font-size: 12px;
            line-height: 108.52%;
            letter-spacing: -0.2px;
            color: $brand-gray-320;

            span {
              color: $brand-orange;
              font-weight: 600;
              font-size: 14px;
              margin-left: 13px;
            }
          }
        }

        &__d2 {
          border-bottom: 5px solid $brand-green;

          .right {
            border-bottom: 5px solid $brand-green;
          }
        }

        &__d3 {
          border-bottom: 5px solid $brand-orange-120;

          .right {
            border-bottom: 5px solid $brand-orange-120;
          }
        }
      }

      &-footer {
        margin: 0 32px;
        background-color: $white;

        .title {
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: $brand-gray-340;
          display: flex;
          align-items: center;
          justify-content: space-around;
          background-color: #FFFFFF;

          img {
            margin-right: 8px;
          }

          div {
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: $brand-gray-320;
            margin: 8px 0px 32px;
          }
        }
      }

    }


  }
}





//   .modal {
//     background: $transparent-140;
//     position: absolute;
//     top: 0;
//     width: 97vw;
//     height: 100%;
//     left: 80px;
//     z-index: 1;

//     &-content {
//       background-color: $brand-gray-240;
//       margin: 50px 75px 0 50px;
//       position: relative;
//       padding: 34px 0;
//       margin-bottom: 20px;


//       .close-button {
//         cursor: pointer;
//       }

//       h1 {
//         font-weight: bold;
//         font-size: 16px;
//         line-height: 19px;
//         text-align: center;
//         color: $brand-gray-180;
//         text-transform: lowercase;
//       }

//       h1::first-line{
//         text-transform: capitalize;
//       }

//       .close-button {
//         background-color: $white;
//         position: absolute;
//         right: 32px;
//         top: 32px;
//       }
//     }

//     &-person {
//       height: 335px;
//       margin: 34px 32px 0;
//       background-color: $white;
//       display: flex;
//       align-items: center;


//       &-image{
//         margin-right: 30px;
//         margin-left: 30px;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         height: 125px;
//         width: 125px;
//         border-radius:50%;
//         background-color: $brand-orange-120;

//         &-text{
//           text-transform: capitalize;
//           color:black;
//           font-size: 34px !important;
//         }

//         .star {
//           position: absolute;
//           bottom: 0;
//           width: 41px;
//           height: 41px;
//           right: 0;
//         }

//       }

//       &-img {
//         margin-left: 38px;
//         margin-right: 70px;
//         position: relative;

//         .star {
//           position: absolute;
//           bottom: 0;
//           width: 41px;
//           height: 41px;
//           right: 0;
//         }
//       }

//       &-text {
//         font-size: 14px;
//         line-height: 17px;
//         color: $brand-gray-320;
//         position: relative;

//         span {
//           font-weight: 600;
//           font-size: 12px;
//           line-height: 15px;
//           color: $brand-gray-340;
//         }

//       }

//     

//    

// @media only screen and (min-width: 1400px) {
//   .home-w {
//     .modal {
//       height: 100%;
//     }
//   }
// }