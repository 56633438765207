@import '../../styles/variables/variables';

.point-list {
  display: flex;
  border-bottom: 1px solid $brand-gray-120;
  margin: 0 30px;
  padding: 10px 0 10px;

  &-content {
    margin-left: 10px;

    h3 {
      color: $brand-gray-140;
      margin: 0;
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
    }

    p {
      color: $brand-orange;
      margin: 0;
      font-weight: 600;
      line-height: 28px;
    }
  }
}
