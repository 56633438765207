@import '../../styles/variables/variables';

.nonMeetingTitle{
    display:flex;
    justify-content:space-around;
    align-items:center;

    input {
        min-width: 120px;
        max-width: 150px;
        height: 50px;
        margin-right: 15px;
        margin-bottom: 11px;
        border-radius: 11px;
        border: solid 1px #ccc;
        padding: 0 1.5%;
    }
}

.nonMeetingNot{
    display: flex;
    padding: 10px 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;
    color: #6c757d;

 
  }