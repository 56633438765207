.comment-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.comment-modal-inner {
  position: relative;
  padding: 48px;
  width: 100%;
  max-width: 1200px;
  max-height: 642px;
  height: 100%;
  background-color: #FFF;
  overflow-y: auto;
}

.commentz {
  display: flex;
  flex-direction: column;
}

.commentz-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: -10px;
  margin-bottom: 24px;
}

.commentz-header h3 {
  color: #ffc107;
}

.commentz-header-text {
  margin-right: 8px;
}

.commentz-header-text-red {
  color: #E43D30;
}

.commentz-header-text-yellow {
  color: #FBC119;
}

.commentz-header-text-green {
  color: #229342;
}

.commentz-header-text span {
  height: 44px;
  font-family: cursive;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.commentz-header-light {
  display: flex;
  align-items: center;
}

.commentz-header-light-first-lamb {
  background-color: #E43D30;
  height: 27px;
  width: 27px;
  margin-right: 10px;
  border-radius: 27px;
  cursor: pointer;

  &--active {
    @extend .commentz-header-light-first-lamb;
    border: 1px solid black;
    box-shadow: 0 0 5px;
  }
}

.commentz-header-light-second-lamb {
  background-color: #FBC119;
  height: 27px;
  width: 27px;
  margin-right: 10px;
  border-radius: 27px;
  cursor: pointer;

  &--active {
    @extend .commentz-header-light-second-lamb;
    border: 1px solid black;
    box-shadow: 0 0 5px;
  }
}

.commentz-header-light-third-lamb {
  background-color: #229342;
  height: 27px;
  width: 27px;
  margin-right: 10px;
  border-radius: 27px;
  cursor: pointer;

  &--active {
    @extend .commentz-header-light-third-lamb;
    border: 1px solid black;
    box-shadow: 0 0 5px;
  }
}

.commentz-header-update {
  margin-right: auto;
  width: 76px;
  height: 20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 108.52%;
  text-align: center;
  letter-spacing: -0.2px;
  color: #FF7A00;
  cursor: pointer;
}

.commentz-header-close img {
  background-color: #FFFFFF;
  cursor: pointer;
  width: 22px;
  height: 22px;
}

.commentz-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}


.commentz-content-noData {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.commentz-content-noData h3 {
  height: 22px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  color: #808080;
}


.commentz-content-newComment {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.commentz-content-newComment-img {
  margin-right: 13px;
}

.commentz-content-newComment-input {
  width: 100%;
  margin-right: 24px;
}

.commentz-content-newComment-input input {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #8B8B8B;
  height: 55px;
  width: 100%;
  padding: 0 16px;
  border: 1px solid #EFEFEF;
  border-radius: 8px;
}

.commentz-content-newComment-input input:focus {
  outline-color: #8b8b8b;
}

.commentz-content-newComment-button button {
  width: 61px;
  height: 20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 108.52%;
  text-align: center;
  letter-spacing: -0.2px;
  color: #FF7A00;
  background-color: #ffffff;
  cursor: pointer;
}

.commentz-content-box {
  min-height: 112px;
  height: 100%;
  background: #F8F8F8;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  padding: 16px 24px 16px 16px;
}

.commentz-content-box-left {
  width: 56px;
  height: 56px;
  margin-right: 24px;
  background-color: #282a35;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.commentz-content-box-left img {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}


.commentz-content-box-middle {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1px;
}

.commentz-content-box-middle-isUpdate {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1px;
  background-color: #FFFFFF;
  padding: 0 24px 6px 16px;
  border-radius: 4px;
}



.commentz-content-box-middle-name span {
  font-family: 'Inter' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #576169;
  height: 24px;
}

.commentz-content-box-middle-date span {
  font-family: 'Inter' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #ACB1B8 !important;
  height: 18px;
}

.commentz-content-box-middle-description span {
  font-family:  sans-serif !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  height: 24px !important;
}

.commentz-content-box-middle-description input {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #576169;
  height: 24px;
  width: 100%;
  margin-bottom: 5px;
  border: 0;
  outline: 0;
}


.commentz-content-box-middle-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.commentz-content-box-middle-buttons-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 32px;
  background: #63C267;
  border-radius: 4px;
}

.commentz-content-box-middle-buttons-submit button {
  width: 54px;
  height: 19px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}

.commentz-content-box-middle-buttons-cancel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 32px;
  background: #ECEFF1;
  border-radius: 4px;
  margin-left: 6px;
}

.commentz-content-box-middle-buttons-cancel button {
  width: 53px;
  height: 19px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #676767;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}


.commentz-content-box-right {
  margin-left: 12px;
  width: 72px;
  display: flex;
  justify-content: center;
  column-gap: 8px;
}

.commentz-content-box-right div {
  width: 32px;
  height: 32px;
  background-color: #CAD0D5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.commentz-content-box-right div:active {
  background-color: #FF7A00;
}


.commentz-update {
  width: 18px;
  height: 18px;
}

.commenhtz-delete {
  width: 14px;
  height: 14px;

}



.commentz-content span {
  width: 100%;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: #565656;
}

.commentz-footer {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.deleteButton {
  background-color: #ea4c89 !important;
  border: 1px solid lighten(gray, 24%);
  height: 48px;
  padding: 0 16px 0 16px !important;
  letter-spacing: .25px;
  border-radius: 24px;
  cursor: pointer;
  color: white !important;
  min-width: 78px;

}

.cancelButton {
  background-color: #282a35 !important;
  border: 1px solid lighten(gray, 24%);
  height: 48px;
  padding: 0 16px 0 16px !important;
  letter-spacing: .25px;
  border-radius: 24px;
  cursor: pointer;
  color: white !important;
  min-width: 78px;
}