.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner {
  position: relative;
  padding: 32px;
  width: 100%;
  max-width: 404px;
  background-color: #FFF;
}

.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -20px;
}

.main-header h3 {
  color: #ffc107;
}

.main-header span {
  margin: 0;
  background-color: #ffffff;
  cursor: pointer;
  color: #282a35;
  
}

.main-content span {
  width: 100%;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: #565656;
}

.main-footer {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.deleteButton {
  background-color: #ea4c89 !important;
  border: 1px solid lighten(gray, 24%);
  height: 48px;
  padding: 0 16px 0 16px !important;
  letter-spacing: .25px;
  border-radius: 24px;
  cursor: pointer;
  color: white !important;
  min-width: 78px;

}

.cancelButton {
  background-color: #282a35 !important;
  border: 1px solid lighten(gray, 24%);
  height: 48px;
  padding: 0 16px 0 16px !important;
  letter-spacing: .25px;
  border-radius: 24px;
  cursor: pointer;
  color: white !important;
  min-width: 78px;
}