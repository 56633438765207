@import '../../styles/variables/variables';

.filter {
  display: flex;
  flex-direction: column;

  &-hw {
    display: flex;

    &-header {
      position: relative;

      input {
        border: 1px solid $brand-gray-220;
        box-sizing: border-box;
        width: 564px;
        height: 41px;
        padding-left: 50px;
        margin-right: 16px;
        background-color: $brand-gray-240;
      }

      img {
        position: absolute;
        left: 19px;
        top: 12px;
      }
    }

    &-buttons {
      display: flex;
      gap: 16px;

      .dropdown {
        position: relative;
        display: inline-block;
      }

    
      .dropdown-content {
        display: none;
        position: absolute;
        border-radius: 10px;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;

        button {
          color: black;
          padding: 12px 16px;
          text-decoration: none;
          display: block;
          width: 100%;
          background-color: white;
        }

        button:first-child{
          border-top-right-radius: 10px;
        }

        button:last-child{
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
         
        }

        
      }

      button:hover {
        background-color: #f1f1f1
      }
    
      .dropdown:hover{

        .dropdown-content {
          display: block;

          button{
            text-align: start;
            
            .active {
              background-color: #FF7A00;
            }
          }
        }

        .dropbtn {
          background-color:  #f1f1f1;
        }

      } 

      button {
        width: 58px;
        height: 41px;
        border: 1px solid $brand-gray-120;
        cursor: pointer;
        background-color: $brand-gray-240;
      }
    }
  }

  &-content {
    &-header {
      height: 66px;
      background: $brand-gray-260;
      border-top-left-radius: 14px;
      border-top-right-radius: 14px;
      margin-top: 22px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      h3 {
        color: $brand-gray-180;
        font-weight: 500;
        font-size: 16px;
        width: 30%;
      }

      .first {
        display: flex;
        width: 25%;
        text-align: left;
        margin-left: 5%;
      }

      .second {
        display: flex;
        width: 33%;
        text-align: left;
      }

      .third {
        display: flex;
        width: 33%;
        text-align: left;
        margin-right: 1%;
      }


    }

    &-data {
      color: $brand-gray-280;
      font-size: 16px;
      line-height: 19px;
      background-color: $white;
      border-bottom: 1px solid $brand-gray-300;
      display: flex;
      padding: 20px 0;
      cursor: pointer;
      align-items: center;
      font-weight: 600;

      span {
        margin-left: 5px;
        text-align: center;
      }

      .first {
        display: flex;
        align-items: center;
        width: 25%;
        text-align: start;
        margin-left: 5%;
        margin-right: 1%;
        text-transform: lowercase;


        p {
          margin-left: 5px;
          text-align: center;
        }

        p::first-line {
          text-transform: capitalize;
        }


        img {
          width: 35px;
          height: 35px;
          background-color: $brand-orange-120;
          border-radius: 50%;
        }

        &-image {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 35px;
          width: 35px;
          border-radius: 50%;
          background-color: $brand-orange-120;

          &-text {
            text-transform: capitalize;
            color: white;
          }

        }
      }

      .second {
        display: flex;
        width: 33%;
        text-align: left;
      }

      .third {
        display: flex;
        width: 33%;
        text-align: left;
        margin-right: 1%;
      }

      
      .fourth{
        margin-right: 30px;
        
        button {
          background-color: white;
          cursor: pointer;
        }
      }


    }

    &-pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $brand-gray-280;
      font-size: 16px;
      line-height: 19px;
      background-color: $white;
      border-bottom: 1px solid $brand-gray-300;
      display: flex;
      padding: 10px 0 20px;

      button {
        width: 10px;
        height: 19px;
        margin-right: 10px;
        background-color: $white;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;

        color: #6C6D6E;


      }

      .active {
        color: #FF7A00;
        width: 8px;
        height: 19px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
      }



      .back {
        font-size: 16px;
        line-height: 19px;
        margin: 12px 10px 0 10px;
        color: $black;
        img {
          background-color: $white;
          cursor: pointer;
        }
      }
      .current {
        width: 8px;
        height: 19px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #6C6D6E;
        margin-left: 5px;
        margin-right: 5px;
      }
      .next {
        font-size: 16px;
        line-height: 19px;
        color: $black;
        margin-left: -2px;
        margin-top: 11px;
        img {
          background-color: $white;
          cursor: pointer;
        }

      }

    }
  }

  .link-default{
    color: inherit;
    text-decoration: inherit;
  }
}

