@import '../../styles/variables/variables';

.login {
  display: flex;
  justify-content: center;
  max-width: 100%;
  height: 100vh;

  &-w {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 62px;
    max-width: 500px;

    &-header {
      font-size: 30px;
      line-height: 36px;
      font-weight: 600;
      color: $brand-orange;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &-footer {
      &-button {
        width: 451px;
        height: 68px;
        background-color: $brand-gray;
        color: $white;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 43px;

        img {
          margin-left: 53px;
        }
      }
    }

    .title-name {
      margin-bottom: 0;
    }

    .coffee {
      color: $brand-gray;
    }
  }
}
