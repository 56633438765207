@import '../../styles/variables/variables';

.see-all {
  display: flex;
  justify-content: center;

  button {
    margin-top: 8px;
    font-size: 14px;
    line-height: 22px;
    color: $brand-gray-160;
    cursor: pointer;
    background-color: $white;
  }
}
