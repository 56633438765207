@import '../../styles/variables/variables';

.urgentNot{
    display: flex;
    padding: 10px 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;
    color: #6c757d;
  }