@import '../../styles/variables/variables';



.critical-w {
  display: flex;
  flex-direction: column;
  margin: 0 24px 0 36px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 14px 0;

  h2 {
    text-align: center;
    padding: 17px 0;
    border-bottom: 1px solid #ededed;
    margin: 0 30px;
    letter-spacing: -0.9px;
    color: #1e5ab6;
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
    margin-bottom: 10px;
  }


  h4 {
    text-align: center;
    border-bottom: 1px solid #ededed;
    margin: 0 30px;
    letter-spacing: -0.9px;
    color: #1e5ab6;
    font-weight: bolder;
    font-size: 16px;
    line-height: 40px;
    margin-bottom: 10px;
  }

  p {
    font-weight: 500;
    font-size: 11px;
    line-height: 22px;
    color: $brand-orange-100;
    margin-top: 0;
  }

  &-content {
    margin-left: 15px;
  }

  .noData{
    display: flex;
    margin: 0 30px;
    padding: 10px 0 10px 10px;
    align-items: center;
    width: 75%;
    height: 70px;
    color: #6c757d;
  }

 

}
