@import '../../styles/variables/variables';

.fullName-line {
  display: flex;
  border-bottom: 1px solid $brand-gray-120;
  margin: 0 30px;
  padding: 10px 0 10px;
  align-items: center;

  img{
    width: 50px;
    height: 50px;
    border-radius: 50px;
  }

  &-image{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    border-radius:50px;
    background-color: $brand-orange-120;


    &-text{
      text-transform: capitalize;
      color:white;
      font-size: 34px !important;
      font-weight: bolder;
    }

  }


  &-content {
    margin-left: 10px;

    h3 {
      color: $brand-gray-140;
      margin: 0;
      font-size: 14px;
      font-weight: 600;
      line-height: 28px;
      text-transform: lowercase;
      cursor: pointer;
      font-style: normal;
      
    }

    h3::first-line{
      text-transform: capitalize;
    }

    p {
      color: $brand-orange;
      margin: 0;
      font-weight: 600;
      line-height: 28px;
    }
  }
}
