.point-content {

    &-row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-text {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            &-first{
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }

            span {
                height: 22px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: #FF7A00;
                margin-right: 5px;
            }

            p {
                height: 22px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                color: #8B8B8B;
            }

        }

        &-error{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            color: #434242;
            opacity: 0.8;
        }

        &-edit {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            h3 {
                height: 22px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                color: #8B8B8B;
                cursor: pointer;
                margin-left: 2px;
            }

            span{
                color: #8B8B8B;
                margin: 0 2px;
            }
        }
    }

    &-add {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 15px;
        height: 45px;

        &-point {
            width: 10%;
            background-color: #EBEBEB;
            display: flex;
            align-items: center;
            justify-content: space-around;
            height: 45px;
            border-radius: 8px;

            select {
                background-color: #EBEBEB;
                border: none;
                resize: none;
                outline: none;
                height: 22px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                color: #8B8B8B;
                cursor: pointer;
                padding-right: 25px;
                text-align: center;
            }


        }

        &-mission {
            width: 90%;
            background-color: #EBEBEB;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 45px;
            border-radius: 8px;

            &-input {
                width: calc(100% - 112px);

                input {
                    display: block;
                    background-color: #EBEBEB;
                    border: none;
                    resize: none;
                    outline: none;
                    height: 22px;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    margin-left: 16px;
                    color: #8B8B8B;
                    width: 100%;
                }
            }

            span {
                margin-right: 25px;
                width: 62px;
                height: 26px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: #6B6B6B;
                cursor: pointer;
            }

        }

    }

}