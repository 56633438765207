@import '../../styles/variables/variables';

.upcoming-list {
  display: flex;
  border-bottom: 1px solid $brand-gray-120;
  padding: 10px 0;

  &-content {
    margin-left: 10px;

    h3 {
      color: $brand-gray-140;
      margin: 0;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
    }

    span {
      font-weight: 500;
      font-size: 11px;
      line-height: 22px;
      color: $brand-blue-100;
    }
  }
}
