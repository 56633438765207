@import-normalize;

* {
  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

html,body {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  font-family: 'Inter', sans-serif ,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif , Verdana ,cursive;
}

body {
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

a {
  text-decoration: none;
}

img {
  vertical-align: middle;
}

button,
fieldset,
iframe {
  border: 0;
}

fieldset,
ul,
ol,
button,
menu {
  padding: 0;
}

ol,
ul {
  list-style: none;
}

textarea {
  resize: vertical;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

td {
  padding: 0;
}

