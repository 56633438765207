@import '../../styles/variables/variables';

.home-w {
  display: flex;
  height: 100vh;

  &-aside {
    background-color: $brand-gray;
    max-width: 80px;
    padding: 36px 22px 0px;
    min-height: 1015px;
    position: fixed;
    height: 100%;

    &-img {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 90px;
      gap: 50px;

      img {
        width: 22px;
      }

      a {
        padding: 12px;
      }

      .active {
        background-color: $brand-orange;
        border-radius: 8px;
      }
    }
  }

  &-right {
    flex: 1;
    background-color: $brand-gray-100;
    margin-left: 80px;
  }

  .home-wrapper {
    display: flex;
    gap: 30px;
  }

  &-main {
    background-color: $brand-gray-100;
    padding: 30px;

    &-left {
      width: 100%;
      max-width: 520px;
      min-width: 480px;
      background-color: $brand-gray-100;

      &__meeting {
        height: 444px;
        background-color: $white;

        .header {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
       
        h2 {
          text-align: center;
          padding: 17px 0;
          border-bottom: 1px solid #ededed;
          margin: 0 30px;
          letter-spacing: -0.9px;
          color: #1e5ab6;
          font-weight: 600;
          font-size: 20px;
          line-height: 40px;
          margin-bottom: 10px;
        }
      }
    }

      &__critical {
        height: 381px;
        margin-top: 30px;
        background-color: $white;
        box-shadow: 0px 4px 14px $transparent-120;
        overflow-x: auto;

        .header {
          height: 35px;
          text-align: center;
          font-weight: 600;
          font-size: 20px;
          line-height: 40px;
          text-align: center;
          letter-spacing: -0.9px;
          color: $brand-gray-180;
          padding-top: 10px;
        }
      }
    }

    &-right {
      width: 100%;
      background-color: $brand-gray-100;
      display: flex;
      gap: 30px;

      &__LP {
        background-color: $brand-gray-100;
        min-width: 330px;
        width: 100%;

        .leadership {
          height: 215px;
          background-color: $white;
          box-shadow: 0px 4px 14px $transparent-120;

          .header {
            font-weight: 600;
            font-size: 20px;
            line-height: 40px;
            letter-spacing: -0.9px;
            color: $brand-gray-180;
            padding-top: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
          }

          &-content {
            display: flex;
            margin: 0 30px;

            &-img {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 10px;

              .points {
                font-weight: 600;
                font-size: 18px;
                line-height: 28px;
                color: $brand-orange;
              }
            }

            &-txt * {
              margin: 0;
              margin-left: 23px;
            }

            &-txt {
              margin-top: 5px;

              h2 {
                font-weight: 600;
                font-size: 18px;
                line-height: 28px;
                color: $brand-gray-140;
                margin-bottom: 5px;
              }

              .ui {
                font-size: 14px;
                line-height: 22px;
                color: $brand-gray-140;
              }

              .date {
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.2px;
                color: $brand-gray-200;
              }
            }
          }
        }

        .point {
          overflow-x: auto;
          min-height: 157px;
          // height: 610px;
          height: 100%;
          max-height: 855px;
          // margin-top: 30px;
          background-color: $white;
          box-shadow: 0px 4px 14px $transparent-120;

          h2 {
            text-align: center;
            padding: 17px 0;
            border-bottom: 1px solid $brand-gray-120;
            margin: 0 30px;
            letter-spacing: -0.9px;
            color: $brand-blue;
            font-weight: 600;
            font-size: 20px;
            line-height: 40px;
            margin-bottom: 10px;
          }

          .see-all {
            margin-top: inherit;
          }
        }
      }

      .upcoming {
        overflow-x: auto;
        min-width: 300px;
        width: 100%;
        min-height: 157px;
        height: 100%;
        max-height: 855px;
        background-color: $white;
        // padding: 0 18px;
        box-shadow: 0px 4px 14px $transparent-120;

        h2 {
          text-align: center;
          padding: 17px 0;
          border-bottom: 1px solid $brand-gray-120;
          margin: 0 30px;
          letter-spacing: -0.9px;
          color: $brand-blue;
          font-weight: 600;
          font-size: 20px;
          line-height: 40px;
          margin-bottom: 10px;
        }

        .today {
          font-size: 14px;
          line-height: 22px;
          color: $brand-gray-160;
          border-bottom: 1px solid $brand-gray-120;
          padding: 10px 0;
        }

        .see-all {
          margin-top: 30px;
        }
      }
    }
  }

  &-header {
    &--nav {
      height: 100px;
      background-color: $white;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .profile {
        border: solid 1px rgb(0 0 0 / 10%);
        width: 259px;
        height: 53px;
        background: $transparent-100;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 22px;
        margin-right: 52px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        color: $brand-gray;
        position: relative;


        small {
          font-size: 12px;
        }

        .picture {
          width: 30px;
          border-radius: 30px;
        }

        .icon {
          width: 10px;
        }

        .dropdown-content {
          display: none;
          position: absolute;
          background: rgb(235, 235, 235);
          width: 259px;
          top: 45px;
          padding: 10px 5px 0 5px;
          z-index: 1;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
        }

        a {
          color: black;
          padding: 12px 16px;
          text-decoration: none;
          background: rgb(248, 248, 248);
          text-align: left;
          display: block;
          width: 100%;
          margin-bottom: 8px;
          border-radius: 10px;
        }

        a:hover {
          border-bottom-right-radius: 10px;
          background-color: white;
        }


      }

      .profile:hover {
        border: solid 1px rgb(0 0 0 / 10%);

        .dropdown-content {
          display: block;
          border-left: solid 1px rgb(0 0 0 / 10%);
          border-right: solid 1px rgb(0 0 0 / 10%);
          border-bottom: solid 1px rgb(0 0 0 / 10%);

        }
      }
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}